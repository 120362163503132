import React from "react";
import styled from "styled-components";

const Container = styled.div<{ maxWidth?: string }>`
  max-width: ${({ maxWidth }) => maxWidth ?? "1200px"};
`;

interface Props {
  maxWidth?: string;
}

export const AppBody = React.memo((props: React.PropsWithChildren<Props>) => {
  return <Container maxWidth={props.maxWidth}>{props.children}</Container>;
});
