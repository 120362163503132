import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Box, Flex, Tooltip } from "@chakra-ui/react";
import React from "react";
import styled from "styled-components";
import { AppBody } from "../../components/AppBody";
import { useMarketList, useSuppliedAddresses } from "../../core/hooks/marketPlace";
import { AvailableSupplyingRow } from "./components/AvailableSupplyingRow";
import { SuppliedRow } from "./components/SuppliedRow";

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.bg1};
  padding: 24px 0px;
  border-radius: ${(props) => props.theme.borderRadius.md};
  min-width: 800px;
  margin-top: 5rem;
`;

const Title = styled.div`
  font-weight: 700;
  color: ${(props) => props.theme.colors.text1};
  font-size: 22px;
  padding: 0px 32px;
`;

const Row = styled.tr``;

const TableTitle = styled.th<{ align?: string }>`
  color: ${(props) => props.theme.colors.text2};
  text-align: ${(props) => props.align || "left"};
  padding: 16px 24px;
  font-size: 14px;
  border-bottom: 1px solid ${(props) => props.theme.colors.border0};
`;

const Table = styled.table`
  width: 100%;
  border-spacing: 0px;
  margin-top: 30px;
`;
export const DashboardPage = React.memo(() => {
  const marketList = useMarketList();
  const suppliedAddresses = useSuppliedAddresses();

  return (
    <AppBody>
      <Container>
        <Title>Supply</Title>
        <Table>
          <Row>
            <TableTitle>Asset</TableTitle>
            <TableTitle align="right">
              <Box>
                <Flex justifyContent={"flex-end"}>
                  APY / Earned
                  <Tooltip label="APY is a measure of how much your money will grow by in one year base on current interest rate." hasArrow placement="top">
                    <InfoOutlineIcon marginLeft={1} />
                  </Tooltip>
                </Flex>
              </Box>
            </TableTitle>
            <TableTitle align="right">Balance</TableTitle>
          </Row>
          {suppliedAddresses.map((i) => (
            <SuppliedRow underlyingAddress={i.underlyingAddress} address={i.address} />
          ))}
        </Table>
      </Container>

      <Container>
        <Title>Available</Title>
        <Table>
          <Row>
            <TableTitle>Asset</TableTitle>
            <TableTitle align="right">Your Wallet</TableTitle>
          </Row>
          {marketList
            .filter((i) => suppliedAddresses.length === 0 || suppliedAddresses.some((a) => a.address != i.address))
            .map((i) => (
              <AvailableSupplyingRow underlyingAddress={i.underlyingAddress} address={i.address} />
            ))}
        </Table>
      </Container>
    </AppBody>
  );
});
