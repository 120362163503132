import { useEthers } from "@usedapp/core";
import React, { useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import styled, { ThemeContext } from "styled-components";
import { AppHeader } from "../components/AppHeader";
import { WrongNetworkModal } from "../components/WrongNetworkModal";
import { SUPPORTED_CHAIN_IDS } from "../core/constants/chains";
import { DashboardPage } from "./Dashboard";
import { MarketsPage } from "./Markets";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1;
`;

export const App = React.memo(() => {
  const themeContext = useContext(ThemeContext);
  const { account, chainId } = useEthers();
  const [isVisibleWrongNetworkModal, setIsVisibleWrongNetworkModal] = useState(false);

  useEffect(() => {
    if (chainId && !SUPPORTED_CHAIN_IDS.includes(chainId)) {
      setIsVisibleWrongNetworkModal(true);
    } else {
      setIsVisibleWrongNetworkModal(false);
    }
  }, [chainId]);

  return (
    <Container>
      <AppHeader />

      <ContentContainer>
        <Routes>
          <Route index element={<MarketsPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
        </Routes>
      </ContentContainer>

      <WrongNetworkModal isOpen={isVisibleWrongNetworkModal} onDismiss={() => {}} />
      <ToastContainer theme={"dark"} toastStyle={{ backgroundColor: themeContext.colors.bg1, borderRadius: themeContext.borderRadius.md }} position="bottom-right" />
    </Container>
  );
});
