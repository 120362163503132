import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AppBody } from "../../components/AppBody";
import { useMarketList } from "../../core/hooks/marketPlace";
import { I18N_NAMESPACES } from "../../core/i18n";
import { IBeToken } from "../../core/models/token";
import { TableHeader } from "./components/TableHeader";
import { TokenRow } from "./components/TokenRow";

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.bg1};
  padding: 24px 0px;
  border-radius: ${(props) => props.theme.borderRadius.md};
  /* width: 100%; */
  /* flex: 1; */
  min-width: 800px;
  margin-top: 5rem;
`;

const Title = styled.div`
  font-weight: 700;
  color: ${(props) => props.theme.colors.text1};
  font-size: 22px;
  padding: 0px 32px;
`;

const Table = styled.table`
  width: 100%;
  margin-top: 30px;
  border-spacing: 0px;
`;

export const MarketsPage = React.memo(() => {
  const { t } = useTranslation([I18N_NAMESPACES.MARKETS]);

  const marketList = useMarketList();

  return (
    <AppBody>
      <Container>
        <Title>Available Markets</Title>
        <Table>
          <TableHeader />
          {(marketList || []).map((item) => (
            <TokenRow address={item.address} underlyingAddress={item.underlyingAddress} />
          ))}
        </Table>
      </Container>
    </AppBody>
  );
});
