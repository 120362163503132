import { useEthers, useTokenBalance } from "@usedapp/core";
import { utils } from "ethers";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { SupplyModal } from "../../../components/SupplyModal";
import { TokenIcon } from "../../../components/TokenIcon";
import { useDecimals, useTokenSymbol } from "../../../core/hooks/token";
import { formatNumber } from "../../../core/utils/formatNumber";

interface Props {
  underlyingAddress: string;
  address: string | null;
}

const Row = styled.tr`
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colors.bg2};
  }
`;

const Cell = styled.td<{ align?: string }>`
  color: ${(props) => props.theme.colors.text1};
  text-align: ${(props) => props.align || "left"};
  padding: 16px 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.border0};
`;

const AssetRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const AvailableSupplyingRow = React.memo((props: Props) => {
  const { account } = useEthers();
  const symbol = useTokenSymbol(props.underlyingAddress);
  const balance = useTokenBalance(props.underlyingAddress, account);
  const decimals = useDecimals(props.underlyingAddress);

  const [isOpenSupplyModal, setIsOpenSupplyModal] = useState(false);

  const walletBalance = useMemo(() => {
    if (!balance || !decimals) return 0;
    return +utils.formatUnits(balance, decimals);
  }, [balance, decimals]);

  return (
    <Row onClick={() => setIsOpenSupplyModal(true)}>
      <Cell>
        <AssetRow>
          <TokenIcon symbol={symbol} />
          <span>{symbol}</span>
        </AssetRow>
      </Cell>
      <Cell align="right">{formatNumber(walletBalance)}</Cell>

      {!!props.address && !!props.underlyingAddress && !!symbol && (
        <SupplyModal
          address={props.address}
          symbol={symbol}
          underlyingAddress={props.underlyingAddress}
          isOpen={isOpenSupplyModal}
          onDismiss={(e) => {
            e.stopPropagation();
            setIsOpenSupplyModal(false);
          }}
        />
      )}
    </Row>
  );
});
