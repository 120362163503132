import { createGlobalStyle, css, DefaultTheme } from "styled-components";

export const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
};

// Migrating to a standard z-index system https://getbootstrap.com/docs/5.0/layout/z-index/
// Please avoid using deprecated numbers
export enum Z_INDEX {
  deprecated_zero = 0,
  deprecated_content = 1,
  dropdown = 1000,
  sticky = 1020,
  fixed = 1030,
  modalBackdrop = 1040,
  offcanvas = 1050,
  modal = 1060,
  popover = 1070,
  tooltip = 1080,
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  (accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `;
  return accumulator;
}, {}) as any;

export const theme: DefaultTheme = {
  colors: {
    primary: "#A703B2",

    // text
    text1: "#FFFFFF",
    text2: "#C3C5CB",
    text3: "#8F96AC",
    text4: "#B2B9D2",
    text5: "#2C2F36",

    //overlay
    overlayBg0: "rgba(26, 32, 70, 0.9)",

    //divider
    divider0: "rgba(255,255,255,0.2)",

    //background
    bg0: "#1A2046",
    bg1: "#333B6D",
    bg2: "#252C56",
    bg3: "#40444F",
    bg4: "#565A69",
    bg5: "#6C7284",
    bg6: "#1A2028",

    // shadow1: "#2F80ED",

    //border
    border0: "#748AA1",

    error: "#FD4040",
    success: "#27AE60",
    warning: "#FF8F00",
  },

  mediaWidth: mediaWidthTemplates,

  borderRadius: {
    md: "12px",
  },
};

export const GlobalStyle = createGlobalStyle`
	* {
		font-family: 'Poppins', sans-serif;
	}

	body {
		margin: 0;
		background-color: ${(props) => props.theme.colors.bg0} !important;
	}

	button {
   user-select: none;
  }
  
  a {
    text-decoration: none;
  }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
`;
