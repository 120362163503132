import { Interface } from "@ethersproject/abi";
import { ChainId, useContractCall, useContractCalls, useEthers } from "@usedapp/core";
import { useMemo } from "react";
import { abi as marketPlaceAbi } from "../../abis/Marketplace.json";
import { abi as bErc20Abi } from "../../abis/BErc20.json";
import { useMarketPlaceAddress } from "./address";
import { utils } from "ethers";
import { SUPPORTED_CHAIN_ID } from "../constants/chains";

const MARKET_PLACE_INTERFACE = new Interface(marketPlaceAbi);

const BERC20_INTERFACE = new Interface(bErc20Abi);

export const useMarketList = (): { address: string | null; underlyingAddress: string }[] => {
  // const marketPlaceAddress = useMarketPlaceAddress();

  // const [result] =
  // useContractCall(
  //   marketPlaceAddress && {
  //     abi: MARKET_PLACE_INTERFACE,
  //     address: marketPlaceAddress,
  //     method: "getAllMarkets",
  //     args: [],
  //   }
  // ) ?? [];

  const { chainId } = useEthers();

  const marketByNetwork: { [key: number]: any[] } = {
    [ChainId.Ropsten]: [
      { address: "0xd6747E1ee1e67b26603948e29ecec7C735726525", underlyingAddress: "0x07865c6E87B9F70255377e024ace6630C1Eaa37F" },
      { address: "0xe498D0d2D1D0221EF4a2f7e63abBE112fA2DCbcE", underlyingAddress: "0x6EE856Ae55B6E1A249f04cd3b947141bc146273c" },
    ],
    // Internal Test
    // [ChainId.Rinkeby]: [
    //   { address: "0x5bDB4835E2870Cb7b903F61bFB4Ec1E307B7DEEA", underlyingAddress: "0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b" },
    //   { address: "0x6899219502f372692d6421FC6ABA44de2670bd05", underlyingAddress: "0xD9BA894E0097f8cC2BBc9D24D308b98e36dc6D02" },
    //   { address: "0xeC2f48F17077e7EFc7De5D6184B7Ba5F86e31bC6", underlyingAddress: "0xc7ad46e0b8a400bb3c915120d284aafba8fc4735" },
    // ],

    // External Test
    [ChainId.Rinkeby]: [
      { address: "0x3805E6797e0ECAe8Dd2E47A14cbA7265ed507284", underlyingAddress: "0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b" },
      { address: "0x4855F42E60a6d1CFc1D9D4FD1E8b60B175e26B5C", underlyingAddress: "0xD9BA894E0097f8cC2BBc9D24D308b98e36dc6D02" },
      { address: "0xa60F31BD1a035e68ac28A71AC621e989b5B6Be8B", underlyingAddress: "0xc7ad46e0b8a400bb3c915120d284aafba8fc4735" },
    ],
    [ChainId.Kovan]: [
      { address: null, underlyingAddress: "0xb7a4F3E9097C08dA09517b5aB877F7a917224ede" },
      { address: null, underlyingAddress: "0x07de306FF27a2B630B1141956844eB1552B956B5" },
    ],
    [ChainId.Goerli]: [
      { address: null, underlyingAddress: "0xD87Ba7A50B2E7E660f678A895E4B72E7CB4CCd9C" },
      // { address: null, underlyingAddress: "0xD87Ba7A50B2E7E660f678A895E4B72E7CB4CCd9C" },
    ],
    [ChainId.Mainnet]: [
      { address: null, underlyingAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48" },
      { address: null, underlyingAddress: "0xdac17f958d2ee523a2206206994597c13d831ec7" },
    ],
  };

  return useMemo(() => {
    return marketByNetwork[chainId || ChainId.Rinkeby];
  }, [chainId]);
};

export const useSuppliedAddresses = () => {
  const { account } = useEthers();
  const marketList = useMarketList();

  const balances =
    useContractCalls(
      marketList.map(
        (item) =>
          item.address && {
            abi: BERC20_INTERFACE,
            address: item.address,
            method: "balanceOf",
            args: [account],
          }
      )
    ) ?? [];

  const decimalsList =
    useContractCalls(
      marketList.map(
        (item) =>
          item.address && {
            abi: BERC20_INTERFACE,
            address: item.address,
            method: "decimals",
            args: [],
          }
      )
    ) ?? [];

  return marketList.filter((i, index) => {
    const [balance] = balances[index] || [];
    const [decimals] = decimalsList[index] || [];
    return balance && decimals ? +utils.formatUnits(balance, decimals) : 0 > 0;
  });
};
