import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Flex, Tooltip } from "@chakra-ui/react";
import React from "react";
import styled from "styled-components";

const Row = styled.tr``;

const Title = styled.th<{ align?: string }>`
  color: ${(props) => props.theme.colors.text2};
  text-align: ${(props) => props.align || "left"};
  padding: 16px 24px;
  font-size: 14px;
  border-bottom: 1px solid ${(props) => props.theme.colors.border0};
`;

export const TableHeader = React.memo(() => {
  return (
    <Row>
      <Title>Market</Title>
      <Title align="right">Total Supply</Title>
      <Title>
        <Flex>
          Supply APY
          <Tooltip label="APY is a measure of how much your money will grow by in one year base on current interest rate." hasArrow placement="top">
            <InfoOutlineIcon marginLeft={1} />
          </Tooltip>
        </Flex>
      </Title>
      <Title align="right">Total Borrow</Title>
    </Row>
  );
});
