import { ERC20Interface, useContractCall, useContractCalls, useContractFunction, useEthers, useTokenAllowance } from "@usedapp/core";
import { Interface } from "@ethersproject/abi";
import { abi } from "../../abis/BErc20.json";
import { useMemo } from "react";
import { flattenDeep } from "lodash-es";
import { IBeToken } from "../models/token";
import { Contract } from "@ethersproject/contracts";
import { utils } from "ethers";
import { BLOCK_PER_YEAR } from "../constants/block";

const BERC20_INTERFACE = new Interface(abi);

export const useUnderlyingAddress = (address: string | undefined) => {
  const underlyingAddress = useContractCall(
    address && {
      abi: BERC20_INTERFACE,
      address: address,
      method: "underlying",
      args: [],
    }
  );

  return useMemo(() => underlyingAddress || [], [underlyingAddress]);
};

export const useExchangeRate = (address: string | undefined) => {
  const [exchangeRate] =
    useContractCall(
      address && {
        abi: BERC20_INTERFACE,
        address: address,
        method: "getExchangeRate",
        args: [],
      }
    ) ?? [];

  return useMemo(() => {
    return exchangeRate;
  }, [exchangeRate]);
};

export const useDecimals = (address: string | undefined | null) => {
  const [decimals] =
    useContractCall(
      address && {
        abi: BERC20_INTERFACE,
        address: address,
        method: "decimals",
        args: [],
      }
    ) ?? [];

  return useMemo(() => {
    return decimals;
  }, [decimals]);
};

export const useMarketSize = (address: string | undefined | null) => {
  const [marketSize] =
    useContractCall(
      address && {
        abi: BERC20_INTERFACE,
        address: address,
        method: "getCash",
        args: [],
      }
    ) ?? [];

  return useMemo(() => {
    return marketSize;
  }, [marketSize]);
};

export const useTotalBorrows = (address: string | undefined | null) => {
  const [totalBorrows] =
    useContractCall(
      address && {
        abi: BERC20_INTERFACE,
        address: address,
        method: "totalBorrows",
        args: [],
      }
    ) ?? [];

  return useMemo(() => {
    return totalBorrows;
  }, [totalBorrows]);
};

export const useSupplyRate = (address: string | null | undefined) => {
  const [supplyRate] =
    useContractCall(
      address && {
        abi: BERC20_INTERFACE,
        address: address,
        method: "getSupplyRate",
        args: [],
      }
    ) ?? [];

  return useMemo(() => {
    return supplyRate;
  }, [supplyRate]);
};

export const useTokenSymbol = (address: string | undefined): string => {
  const [symbol] =
    useContractCall(
      address && {
        abi: ERC20Interface,
        address: address,
        method: "symbol",
        args: [],
      }
    ) ?? [];

  return useMemo(() => symbol, [symbol]);
};

export const useDeposit = (contract: Contract) => {
  const contractFunction = useContractFunction(contract, "deposit", { transactionName: "Deposit" });
  return useMemo(() => contractFunction, [contractFunction]);
};

export const useUnderlyingBalance = (address: string | null | undefined) => {
  const { account } = useEthers();
  const [balance] =
    useContractCall(
      address && {
        abi: BERC20_INTERFACE,
        address: address,
        method: "balanceOfUnderlying",
        args: [account],
      }
    ) ?? [];

  return useMemo(() => balance, [balance]);
};

export const useApy = (address: string | null | undefined): number => {
  const supplyRate = useSupplyRate(address);

  return useMemo(() => {
    if (!supplyRate) return 0;

    return (Math.pow(1 + +utils.formatUnits(supplyRate, 18) / BLOCK_PER_YEAR, BLOCK_PER_YEAR) - 1) * 100;
  }, [supplyRate]);
};
