import React from "react";
import styled from "styled-components";
import { images } from "../core/constants/images";

interface Props {
  symbol: string;
  size?: number;
}

const Icon = styled.img<{ size?: number; backgroundColor?: string }>`
  height: ${(props) => (props.size ? `${props.size}px` : "50px")};
  width: ${(props) => (props.size ? `${props.size}px` : "50px")};
  object-fit: contain;
  background-color: ${(props) => props.backgroundColor || "#fff"};
  border-radius: 100%;
`;

const getIconBySymbol = (symbol: string) => {
  switch (symbol) {
    case "USDT":
      return images.usdt;
    case "USDC":
      return images.usdc;
    case "DAI":
      return images.dai;
    default:
      return images.tokenPlaceholder;
  }
};

export const TokenIcon = React.memo((props: Props) => {
  return <Icon src={getIconBySymbol(props.symbol)} size={props.size} backgroundColor={props.symbol ? "transprent" : "#fff"} />;
});
