import * as React from "react";
import styled, { css, keyframes } from "styled-components";

interface Props {
  size?: number;
  hideText?: boolean;
  strokeWidth?: number;
}

const clip = keyframes`
  0% {transform: rotate(0deg)}
  50% {transform: rotate(180deg)}
  100% {transform: rotate(360deg)}
`;

const Spin = styled.span<{ size?: number; strokeWidth?: number }>`
  background: transparent !important;
  width: ${(props) => (props.size ? `${props.size}px` : "150px")};
  height: ${(props) => (props.size ? `${props.size}px` : "150px")};
  border-radius: 100%;
  border: ${(props) => (props.strokeWidth ? `${props.strokeWidth}px` : "5px")} solid;
  border-color: #fff;
  border-bottom-color: ${(props) => props.theme.colors.primary};
  display: inline-block;
  animation: ${clip} ${1}s 0s infinite linear;
  animation-fill-mode: both;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
`;

const Container = styled.div<{ size?: number }>`
  position: relative;
  width: ${(props) => (props.size ? `${props.size}px` : "150px")};
  height: ${(props) => (props.size ? `${props.size}px` : "150px")};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

const Text = styled.div`
  color: ${(props) => props.theme.colors.text2};
`;

export const Spinner = React.memo((props: Props) => {
  return (
    <Container size={props.size}>
      <Spin size={props.size} strokeWidth={props.strokeWidth}></Spin>
      {!props.hideText && <Text>Waiting</Text>}
    </Container>
  );
});
