import {
  ERC20Interface,
  getExplorerAddressLink,
  getExplorerTransactionLink,
  shortenAddress,
  transactionErrored,
  useContractFunction,
  useEthers,
  useTokenAllowance,
  useTokenBalance,
} from "@usedapp/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { MARKET_PLACE_ADDRESSES } from "../../core/constants/addresses";
import { useMarketPlaceAddress } from "../../core/hooks/address";
import { useApy, useDecimals, useDeposit, useExchangeRate } from "../../core/hooks/token";
import { IBeToken } from "../../core/models/token";
import { getContract } from "../../core/utils/getContract";
import { AppModal } from "../Modal";
import { RoundedButton } from "../RoudedButton";
import { utils } from "ethers";
import { abi as BErc20 } from "../../abis/BErc20.json";
import { Interface } from "@ethersproject/abi";
import { TransactionLoading } from "./TransactionLoading";
import { TokenIcon } from "../TokenIcon";
import { images } from "../../core/constants/images";
import { BigNumber } from "@ethersproject/bignumber";
import { BIG_NUMBER_MAX_VALUE } from "../../core/constants/bigNumber";
import { toast } from "react-toastify";
import { formatNumber } from "../../core/utils/formatNumber";
import { isFloat } from "../../core/utils/number";
import { CopyIcon } from "@chakra-ui/icons";
import { useClipboard } from "@chakra-ui/react";

interface Props {
  isOpen: boolean;
  onDismiss: (e: React.MouseEvent | React.KeyboardEvent) => void;
  symbol: string;
  underlyingAddress: string;
  address: string;
}

enum ACTION {
  SUPPLY,
  WITHDRAW,
}

const Header = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  border-bottom: 1px solid ${(props) => props.theme.colors.divider0};
  padding: 16px;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  color: #fff;
  font-size: 20px;
  text-align: center;
`;

const CloseIcon = styled.img`
  align-self: center;
  justify-self: flex-end;
`;

const Content = styled.div`
  padding: 0px 40px;
`;

const AmountContainer = styled.div`
  display: flex;
  /* grid-template-columns: 50px 1fr 50px; */
  padding: 20px 0px;
  border-bottom: 1px solid ${(props) => props.theme.colors.divider0};
  align-items: center;
`;

const AmountInput = styled.input`
  background-color: transparent;
  border: none;
  text-align: center;
  color: #fff;
  font-size: 25px;
  font-weight: 700;
  outline: none;
  width: 100%;
  flex: 1;
`;

const MaxAmount = styled.div`
  color: ${(props) => props.theme.colors.text2};
  cursor: pointer;
`;

const TabContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px 0px;
  border: 1px solid #ebcef1;
  border-radius: 100px;
`;

const TabItem = styled.div<{ active: boolean }>`
  cursor: pointer;
  color: ${(props) => (props.active ? props.theme.colors.primary : props.theme.colors.text1)};
  text-align: center;
  border-radius: 100px;
  background-color: ${(props) => (props.active ? "#EBCEF1" : "transparent")};
  padding: 10px 0px;
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const InfomartionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.colors.text1};
`;

const RowTitle = styled.span`
  color: ${(props) => props.theme.colors.text2};
`;

const RowValue = styled.span<{ cursor?: string }>`
  color: ${(props) => props.theme.colors.text1};
  font-weight: 500;
  cursor: ${(props) => props.cursor};
`;

const SubmitBtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px;
`;

const Footer = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.divider0};
  padding: 24px 0px;
`;

const ViewOnExplorerText = styled.div`
  color: rgb(33, 114, 229);
  /* color: ${(props) => props.theme.colors.bg2} */
  font-weight: 500;
`;

export const SupplyModal = React.memo((props: Props) => {
  const [action, setAction] = useState(ACTION.SUPPLY);
  const { account, chainId } = useEthers();

  const contract = getContract(props.address, new Interface(BErc20));
  const balance = useTokenBalance(props.address, account);
  const decimals = useDecimals(props.address);
  const exchangeRate = useExchangeRate(props.address);

  const supplyBalance = useMemo(() => {
    if (!balance || !decimals || !exchangeRate) return 0;

    return +utils.formatUnits(balance, decimals) * +utils.formatUnits(exchangeRate);
  }, [balance, decimals, exchangeRate]);

  const supplyApy = useApy(props.address);

  const underlyingDecimals = useDecimals(props.underlyingAddress);
  const allowance = useTokenAllowance(props.underlyingAddress, account, props.address);
  const formattedAllowance = useMemo(() => (allowance ? +utils.formatUnits(allowance, underlyingDecimals) : 0), [allowance]);
  const underlyingContract = getContract(props.underlyingAddress, ERC20Interface);
  const underlyingBalance = useTokenBalance(props.underlyingAddress, account);

  const walletBalance = useMemo(() => {
    if (!underlyingBalance || !underlyingDecimals) return 0;
    return +utils.formatUnits(underlyingBalance, underlyingDecimals);
  }, [underlyingBalance, underlyingDecimals]);

  const approveFunction = useContractFunction(underlyingContract, "approve");
  const depositFunction = useContractFunction(contract, "deposit");
  const withdrawFunction = useContractFunction(contract, "redeem");

  const [amount, setAmount] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPressMaxBtn, setIsPressMaxBtn] = useState(false);

  const { hasCopied: hasCopiedTokenAddress, onCopy: onCopyTokenAddress } = useClipboard(props.underlyingAddress);

  //Set loading
  useEffect(() => {
    if (approveFunction.state.status === "Mining" || depositFunction.state.status === "Mining" || withdrawFunction.state.status === "Mining") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [approveFunction.state, depositFunction.state, withdrawFunction.state]);

  //Show toast if success or error
  useEffect(() => {
    if (depositFunction.state.errorMessage) {
      toast.error(depositFunction.state.errorMessage.replace("execution reverted: ", ""), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (depositFunction.state.status === "Success") {
      toast.success(
        <div>
          <div>
            Supply {formatNumber(+(amount || "0"))} {props.symbol}
          </div>
          {chainId && depositFunction.state.transaction?.hash && (
            <a href={getExplorerTransactionLink(depositFunction.state.transaction?.hash, chainId)} target={"_blank"}>
              <ViewOnExplorerText>View on Explorer</ViewOnExplorerText>
            </a>
          )}
        </div>,
        {
          position: toast.POSITION.TOP_RIGHT,
          onOpen: () => {
            setAmount("");
          },
          autoClose: 15000,
        }
      );
    }
  }, [depositFunction.state]);

  useEffect(() => {
    if (withdrawFunction.state.errorMessage) {
      toast.error(withdrawFunction.state.errorMessage.replace("execution reverted: ", ""), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (withdrawFunction.state.status === "Success") {
      toast.success(
        <div>
          <div>
            Withdraw {formatNumber(+(amount || "0"))} {props.symbol}
          </div>
          {chainId && withdrawFunction.state.transaction?.hash && (
            <a href={getExplorerTransactionLink(withdrawFunction.state.transaction?.hash, chainId)} target={"_blank"}>
              <ViewOnExplorerText>View on Explorer</ViewOnExplorerText>
            </a>
          )}
        </div>,
        {
          position: toast.POSITION.TOP_RIGHT,
          onOpen: () => {
            setAmount("");
          },
          autoClose: 15000,
        }
      );
    }
  }, [withdrawFunction.state]);

  const onChangeSupplyTab = useCallback(() => {
    setAction(ACTION.SUPPLY);
    setAmount(null);
  }, []);

  const onChangeWithdrawTab = useCallback(() => {
    setAction(ACTION.WITHDRAW);
    setAmount(null);
  }, []);

  const onEnable = useCallback(() => {
    if (!account) {
      toast.error("You need to connect wallet first.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    approveFunction.send(props.address, BIG_NUMBER_MAX_VALUE);
  }, [approveFunction, underlyingBalance, account]);

  const onDeposit = useCallback(() => {
    if (!amount) return;

    depositFunction.send(BigInt(Math.floor(+amount * Math.pow(10, decimals))));
  }, [depositFunction]);

  const onWithdraw = useCallback(() => {
    if (!amount) return;

    withdrawFunction.send(BigInt(Math.floor(+amount * Math.pow(10, decimals))));
  }, [withdrawFunction]);

  const onAfterClose = useCallback(() => {
    setAmount(null);
  }, [setAmount]);

  return (
    <AppModal isOpen={props.isOpen} onDismiss={props.onDismiss} width={500}>
      {isLoading ? (
        <TransactionLoading />
      ) : (
        <div>
          <Header>
            <div />
            <TitleContainer>
              <TokenIcon symbol={props.symbol} size={35} />
              <Title>{props.symbol}</Title>
            </TitleContainer>
            <CloseIcon src={images.closeFilled} onClick={props.onDismiss} />
          </Header>

          <Content>
            <AmountContainer>
              <div style={{ opacity: 0 }}>Max</div>
              <AmountInput
                placeholder="0"
                value={!!amount ? `${isPressMaxBtn ? "~" : ""} ${isPressMaxBtn ? (+amount).toFixed(6) : amount}`.trim() : ""}
                // value={amount || ""}
                // type="number"
                onChange={(e: any) => {
                  setAmount((e.target.value as string).replace("~", "").trim());
                  setIsPressMaxBtn(false);
                }}
              />
              <MaxAmount
                onClick={() => {
                  setAmount(action === ACTION.SUPPLY ? walletBalance.toString() : supplyBalance.toString());
                  setIsPressMaxBtn(true);
                }}
              >
                Max
              </MaxAmount>
            </AmountContainer>

            <TabContainer>
              <TabItem active={action === ACTION.SUPPLY} onClick={onChangeSupplyTab}>
                Supply
              </TabItem>
              <TabItem active={action === ACTION.WITHDRAW} onClick={onChangeWithdrawTab}>
                Withdraw
              </TabItem>
            </TabContainer>

            <InformationContainer>
              <InfomartionRow>
                <RowTitle>Supply APY</RowTitle>
                <RowValue>{formatNumber(supplyApy)}%</RowValue>
              </InfomartionRow>

              {action === ACTION.WITHDRAW && !!balance && !!exchangeRate && (
                <InfomartionRow>
                  <RowTitle>Current Supply Balance</RowTitle>
                  <RowValue>
                    ~{isFloat(supplyBalance) ? supplyBalance.toFixed(6) : supplyBalance.toString()} {props.symbol}
                  </RowValue>
                </InfomartionRow>
              )}
            </InformationContainer>

            <SubmitBtnContainer>
              {+formattedAllowance === 0 ? (
                <RoundedButton onClick={onEnable}>Enable</RoundedButton>
              ) : action === ACTION.SUPPLY ? (
                <RoundedButton onClick={onDeposit} disabled={parseFloat(amount || "0") == 0 || parseFloat(amount || "0") > walletBalance || Number.isNaN(+(amount || ""))}>
                  Supply
                </RoundedButton>
              ) : (
                <RoundedButton onClick={onWithdraw} disabled={parseFloat(amount || "0") == 0 || parseFloat(amount || "0") > supplyBalance || Number.isNaN(+(amount || ""))}>
                  Withdraw
                </RoundedButton>
              )}
            </SubmitBtnContainer>

            <Footer>
              <InformationContainer>
                {!!underlyingBalance && (
                  <InfomartionRow>
                    <RowTitle>Wallet Balance</RowTitle>
                    <RowValue>{walletBalance}</RowValue>
                  </InfomartionRow>
                )}
                <InfomartionRow>
                  <RowTitle>Token Address</RowTitle>
                  <RowValue onClick={onCopyTokenAddress} cursor="pointer">
                    {hasCopiedTokenAddress ? (
                      "Copied"
                    ) : (
                      <>
                        {shortenAddress(props.underlyingAddress)}
                        <CopyIcon marginLeft={"8px"} />
                      </>
                    )}
                  </RowValue>
                </InfomartionRow>
              </InformationContainer>
            </Footer>
          </Content>
        </div>
      )}
    </AppModal>
  );
});
