import React from "react";
import styled from "styled-components";
import { AppModal } from "./Modal";
import { Spinner } from "./Spinner";

interface Props {
  isOpen: boolean;
  onDismiss: () => void;
}

const Container = styled.div`
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 28px;
  color: ${(props) => props.theme.colors.text1};
  margin-top: 24px;
`;

const Description = styled.div`
  color: ${(props) => props.theme.colors.text2};
  text-align: center;
  margin-top: 8px;
`;

export const WrongNetworkModal = React.memo((props: Props) => {
  return (
    <AppModal width={380} isOpen={props.isOpen} onDismiss={props.onDismiss}>
      <Container>
        <Spinner size={50} hideText strokeWidth={4} />
        <Title>Wrong Network</Title>
        <Description>You connected to unsupported network. Please change network to Rinkeby</Description>
      </Container>
    </AppModal>
  );
});
