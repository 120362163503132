import { useBlockMeta, useBlockNumber, useEthers, useTokenBalance } from "@usedapp/core";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { SupplyModal } from "../../../components/SupplyModal";
import { TokenIcon } from "../../../components/TokenIcon";
import { useApy, useDecimals, useMarketSize, useSupplyRate, useTokenSymbol, useTotalBorrows, useUnderlyingAddress } from "../../../core/hooks/token";
import { formatNumber } from "../../../core/utils/formatNumber";
import { utils } from "ethers";
import { BLOCK_PER_YEAR } from "../../../core/constants/block";

interface Props {
  address: string | null;
  underlyingAddress: string;
}

const Row = styled.tr`
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colors.bg2};
  }
`;

const Cell = styled.td<{ align?: string }>`
  color: ${(props) => props.theme.colors.text1};
  text-align: ${(props) => props.align || "left"};
  padding: 16px 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.border0};
`;

const AssetRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Icon = styled.img`
  height: 50px;
  width: 50px;
  object-fit: contain;
`;

const ApyText = styled.div<{ color?: string | null }>`
  color: ${(props) => props.color || props.theme.colors.text1};
`;

export const TokenRow = React.memo((props: Props) => {
  const [isOpenSupplyModal, setIsOpenSupplyModal] = useState(false);

  const symbol = useTokenSymbol(props.underlyingAddress);
  const marketSize = useMarketSize(props.address);
  const decimals = useDecimals(props.address);
  const totalBorrows = useTotalBorrows(props.address);

  const formattedMarketSize = useMemo(() => {
    if (!marketSize || !decimals) return 0;
    return +utils.formatUnits(marketSize, decimals);
  }, [marketSize, decimals]);

  const formattedTotalBorrows = useMemo(() => {
    if (!totalBorrows) return 0;
    return +utils.formatUnits(totalBorrows, decimals);
  }, [totalBorrows, decimals]);

  const apy = useApy(props.address);

  return (
    <Row onClick={() => setIsOpenSupplyModal(true)}>
      <Cell>
        <AssetRow>
          <TokenIcon symbol={symbol} />
          <span>{symbol}</span>
        </AssetRow>
      </Cell>
      <Cell align="right">{formatNumber(formattedMarketSize)}</Cell>
      <Cell>
        <ApyText color={apy > 0 ? "#00FFCB" : undefined}>{formatNumber(apy)}%</ApyText>
      </Cell>

      <Cell align="right">{formatNumber(formattedTotalBorrows)}</Cell>
      {!!props.address && !!props.underlyingAddress && !!symbol && (
        <SupplyModal
          address={props.address}
          symbol={symbol}
          underlyingAddress={props.underlyingAddress}
          isOpen={isOpenSupplyModal}
          onDismiss={(e) => {
            e.stopPropagation();
            setIsOpenSupplyModal(false);
          }}
        />
      )}
    </Row>
  );
});
