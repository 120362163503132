import React from "react";
import { useMatch, useResolvedPath } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div<{ match?: boolean }>`
  display: flex;
  position: relative;
  color: ${(props) => (props.match ? props.theme.colors.text1 : props.theme.colors.text3)};
  font-weight: ${(props) => (props.match ? 700 : 500)};
  border: ${(props) => (props.match ? `1px solid ${props.theme.colors.border0}` : "none")};
  padding: 6px 24px;
  border-radius: ${(props) => props.theme.borderRadius.md};
  background: ${(props) => (props.match ? `radial-gradient(circle 35px at bottom, ${props.theme.colors.primary} 10%, ${props.theme.colors.bg2})` : "transparent")};

  &:hover {
    background-color: ${(props) => props.theme.colors.bg2};
  }
`;

interface Props {
  label: string;
  route: string;
}

export const TabItem = React.memo((props: Props) => {
  const resolved = useResolvedPath(props.route);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link to={props.route}>
      <Container match={!!match}>{props.label}</Container>
    </Link>
  );
});
