import React from "react";
import styled from "styled-components";
import { Spinner } from "../Spinner";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 16px;
  overflow: hidden;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 22px;
  color: #fff;
`;

const SpinnerContainer = styled.div`
  margin: 40px 0px;
`;

const Description = styled.div`
  color: ${(props) => props.theme.colors.text2};
  font-size: 12px;
`;

export const TransactionLoading = React.memo(() => {
  return (
    <Container>
      <Title>Confirm Transaction</Title>
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
      <Description>Confirm the transaction on your wallet</Description>
    </Container>
  );
});
