import React, { useContext, useMemo } from "react";
import Modal from "react-modal";
import { ThemeContext } from "styled-components";

Modal.setAppElement("#root");

interface Props {
  isOpen: boolean;
  onDismiss: (e: React.MouseEvent | React.KeyboardEvent) => void;
  onAfterClose?: () => void;
  width?: number;
}

export const AppModal = React.memo((props: React.PropsWithChildren<Props>) => {
  const themeContext = useContext(ThemeContext);

  const customStyles = useMemo(
    () => ({
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        zIndex: 10,
        backgroundColor: themeContext.colors.bg1,
        border: "none",
        borderRadius: themeContext.borderRadius.md,
        boxShadow: `0 0px 60px rgba(26, 30, 55, 0.8)`,
        padding: 0,
        width: props.width ? `${props.width}px` : "auto",
      },
      overlay: {
        backgroundColor: themeContext.colors.overlayBg0,
      },
    }),
    [themeContext, props.width]
  );

  return (
    <Modal isOpen={props.isOpen} shouldCloseOnOverlayClick shouldCloseOnEsc onAfterClose={props.onAfterClose} onRequestClose={props.onDismiss} style={customStyles}>
      {props.children}
    </Modal>
  );
});
